import styles from './table-module.module.scss';

export interface IMobileTableProps {
	header?: React.JSX.Element[][];
	body?: (string | React.JSX.Element | React.JSX.Element[])[];
}

export const MobileTable = ({ header, body }: IMobileTableProps): React.JSX.Element => {
	const headerRow = header && header.map((row) => row);
	const bodyRows = body && body.map((row) => row);

	if (!bodyRows && !headerRow) return <></>;

	if (!bodyRows)
		return (
			<div className={styles.mobileTable}>
				<div>
					{headerRow?.map((item, key) => (
						<div key={`header-cell_${key}`}>{item}</div>
					))}
				</div>
			</div>
		);

	return (
		<div className={styles.mobileTable}>
			{bodyRows.map((row, rowKey) => (
				<div key={`body-row_${rowKey}`}>
					{Array.isArray(row) &&
						row.map((cell, cellKey) => (
							<div key={`body-row_${rowKey}-cell_${cellKey}`} className={styles.mobileRow}>
								{headerRow && headerRow[cellKey] && <div className={styles.mobileHeader}>{headerRow[cellKey]}</div>}
								<div className={styles.mobileCell}>{cell}</div>
							</div>
						))}
				</div>
			))}
		</div>
	);
};

export default MobileTable;
