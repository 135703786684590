import { attributesToProps, domToReact, Element } from 'html-react-parser';
import { IntlShape } from 'react-intl';
import Link from 'next/link';

import { createValidExternalURL, isValidExternalURL, URLContainsAllowedDomain } from 'utils/url';
import { DocReader } from 'components/docreader/docreader';

import styles from './parse-link.module.scss';

export const parseLink = (typedDomNode: Element, intl: IntlShape): React.JSX.Element => {
	const props = attributesToProps(typedDomNode.attribs);
	const { href } = props;

	if (!href) {
		return <></>;
	}

	// Is valid and potential external URL
	if (isValidExternalURL(href)) {
		const url = createValidExternalURL(href);
		const label = typedDomNode.children
			.map((item: any) => {
				if (item.type === 'text') {
					return item.data;
				}

				if (item.name === 'img' && item.attribs?.alt) {
					return item.attribs.alt;
				}

				return '';
			})
			.join(' ');

		// Force external URL in new tab with noopener
		const externalProps = {
			...props,
			href: url.href,
			rel: 'noreferrer noopener',
			['aria-label']: `${label} ${intl.formatMessage({ id: 'global.externalLink' })}`
		};

		// Is external URL on same domain
		if (URLContainsAllowedDomain(url)) {
			return (
				<Link {...props} href={url.pathname}>
					{typedDomNode.children && domToReact(typedDomNode.children)}
				</Link>
			);
		}

		return (
			<span className={styles.linkWrapper}>
				<a {...externalProps} className={styles.link}>
					{typedDomNode.children && domToReact(typedDomNode.children)}
				</a>
				<DocReader url={url.toString()} className={styles.docReader} />
			</span>
		);
	} else {
		return (
			<Link {...props} href={href}>
				{typedDomNode.children && domToReact(typedDomNode.children)}
			</Link>
		);
	}
};
