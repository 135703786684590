import type { Element } from 'domhandler/lib/node';
import type { DOMNode } from 'html-react-parser';

import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import { useIntl } from 'react-intl';

import Typography from 'components/typography';
import { ALLOWED_TD_ATTRIBUTES, TABLE_TAGS } from './constants';
import { ALLOWED_HTML_TAGS, ALLOWED_IMAGE_ATTRIBUTES, ALLOWED_LINK_ATTRIBUTES, VALID_SCHEMES } from './constants';

import { parseLink } from './parse-elements/parse-link';
import { parseImage } from './parse-elements/parse-image';
import { parseHeading } from './parse-elements/parse-heading';
import { parseDiv } from './parse-elements/parse-div';
import * as parseTable from './parse-elements/parse-table';

export const sanitizeAndParseHtml = (
	dirtyHtml: string,
	boldIntro?: boolean
): string | React.JSX.Element | React.JSX.Element[] => {
	try {
		const sanitizedHtml = cleanHtml(dirtyHtml, true);
		const euroSignReplaced = sanitizedHtml.replaceAll('€ ', '€&nbsp;');

		const parsedHtml = parseHtml(euroSignReplaced, boldIntro);

		return parsedHtml;
	} catch (error: any) {
		console.error(error);
		return <></>;
	}
};

export const cleanHtml = (dirtyHtml: string, isText?: boolean): string => {
	try {
		return sanitizeHtml(dirtyHtml, {
			allowedTags: isText && [...TABLE_TAGS, ...ALLOWED_HTML_TAGS],
			allowedSchemes: VALID_SCHEMES,
			allowedAttributes: {
				a: ALLOWED_LINK_ATTRIBUTES,
				img: ALLOWED_IMAGE_ATTRIBUTES,
				th: ALLOWED_TD_ATTRIBUTES,
				td: ALLOWED_TD_ATTRIBUTES
			}
		});
	} catch (error: any) {
		console.error(error);
		return '';
	}
};

const parseHtml = (sanitizedHtml: string, boldIntro?: boolean): string | React.JSX.Element | React.JSX.Element[] =>
	parse(sanitizedHtml, {
		replace: (domNode) => parseNode(domNode, boldIntro)
	});

export const parseNode = (domNode: DOMNode, boldIntro?: boolean) => {
	const intl = useIntl();
	const typedDomNode = domNode as Element;

	if (typedDomNode.attribs) {
		switch (typedDomNode.name) {
			case 'a':
				return parseLink(typedDomNode, intl);

			case 'div':
				return parseDiv(typedDomNode);

			case 'img':
				return parseImage(typedDomNode);

			case 'h2':
			case 'h3':
			case 'h4':
			case 'h5':
				return parseHeading(typedDomNode);

			case 'table':
				return parseTable.table(typedDomNode);
			case 'thead':
				return parseTable.thead(typedDomNode);
			case 'tbody':
				return parseTable.tbody(typedDomNode);
			case 'tfoot':
				return parseTable.tfoot(typedDomNode);
			case 'th':
				return parseTable.th(typedDomNode);
			case 'tr':
				return parseTable.tr(typedDomNode);
			case 'td':
				return parseTable.td(typedDomNode);
			case 'caption':
				return parseTable.caption(typedDomNode);

			default:
				return null;
		}
	}

	const isTagElement = (element: any): element is Element => {
		return element !== undefined;
	};

	if (
		boldIntro &&
		'data' in domNode &&
		typedDomNode.parentNode?.prev === null &&
		isTagElement(typedDomNode.parent) &&
		typedDomNode.parent?.name !== 'li'
	) {
		return (
			<Typography tag="span" variant="intro">
				{domNode.data}
			</Typography>
		);
	}

	return null;
};
