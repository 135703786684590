import { useEffect, useState } from 'react';
import { Button } from 'components/button';
import { sanitizeSidebarLinks } from 'utils/sanitize-sidebar-links';

import styles from './sidebar-link.module.scss';

export interface ISidebarLinkProps {
	title?: string;
	href?: string;
	description?: string;
}

const SidebarLink = (props: ISidebarLinkProps): React.JSX.Element => {
	const { title, href, description } = props;
	const [sanitizedTitle, setSanitizedTitle] = useState<string | undefined>(title);
	const [sanitizedDescription, setSanitizedDescription] = useState<string | undefined>(description);

	useEffect(() => {
		if (title) {
			setSanitizedTitle(sanitizeSidebarLinks(title));
		}
		if (description) {
			setSanitizedDescription(sanitizeSidebarLinks(description));
		}
	}, [title, description]);

	return (
		<div className={styles.sidebarlink}>
			<Button title={sanitizedTitle} variant="tertiary-dark" href={href} />
			{description && (
				<span id="description" className={styles.label}>
					{sanitizedDescription}
				</span>
			)}
		</div>
	);
};

export { SidebarLink };
