import { createElement } from 'react';
import { Element } from 'html-react-parser';

export const parseHeading = (typedDomNode: Element) => {
	const child: any = typedDomNode.children[0];
	const childWithText: any = typedDomNode.children.find((child) => child.type === 'text');

	if (child.type === 'text' && child.data) {
		return createElement(typedDomNode.name, { id: child.data?.toLowerCase() }, child.data);
	} else if (child.type === 'tag' && child.children?.length) {
		const header = createElement(
			typedDomNode.name,
			{ id: child.children[0]?.data?.toLowerCase() },
			child.children[0]?.data
		);
		return createElement('a', child.attribs, header);
	} else {
		return createElement(typedDomNode.name, { id: childWithText?.data?.toLowerCase() }, childWithText?.data);
	}
};
