import { attributesToProps, Element } from 'html-react-parser';

export const parseImage = (typedDomNode: Element): React.JSX.Element => {
	const props = attributesToProps(typedDomNode.attribs);
	const altIsFileName = altIsFileNameFromSource(props?.src, props?.alt);
	const alt = !altIsFileName ? props.alt : undefined;

	return (
		<img title={alt} style={{ ...props.style, maxHeight: '400px', objectFit: 'contain' }} src={props.src} alt={alt} />
	);
};

// Strapi adds fallback alt that can't be blocked
// Alt MUST be undefined if not explicitly set
// Not loving this solution though...
const altIsFileNameFromSource = (src?: string, alt?: string) => {
	if (!src || !alt) return false;

	const sourcePath = src.split('/') || [];
	const fileName = sourcePath.pop();
	const splitName = fileName?.split('_');

	splitName?.pop();

	return splitName?.every((part) => alt.includes(part));
};
