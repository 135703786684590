import clsx from 'clsx';
import { DownloadFieldsFragment } from 'api/graphql-types';

import { Icon } from 'components/icon/icon';
import { Button } from 'components/button';
import { Typography } from 'components/typography';

import IcnChevronRight from 'public/assets/icons/chevron-right.svg';

import styles from './downloads.module.scss';

export interface IDownloadsProps {
	module: DownloadFieldsFragment;
}

const Downloads = (props: IDownloadsProps): React.JSX.Element => {
	const darkStyling = props.module.defaultModuleOptions?.extendedBackgroundColor === 'darkblue';

	return (
		<div className={clsx(styles.downloads, darkStyling && styles.dark)}>
			{props.module.downloadsTitle?.value && (
				<Typography tag={props.module.downloadsTitle?.type || 'h2'} variant="h2">
					{props.module.downloadsTitle.value}
				</Typography>
			)}
			<div className={styles.items}>
				{props.module.downloads?.data.map((download, index) => (
					<div className={clsx(styles.darkItem, darkStyling && styles.lightItem)} key={`${index}-file`}>
						{downloadComponent(
							renderDownloadLink(
								{
									ext: download.attributes?.ext || '',
									size: download.attributes?.size || 0
								},
								download.attributes?.name
							),
							download?.attributes?.url || '/'
						)}
						{download?.attributes?.caption && (
							<span id="description" className={styles.label}>
								{download?.attributes?.caption}
							</span>
						)}
					</div>
				))}
				{props.module.downloadLinks?.map((downloadLink, index) => (
					<div className={clsx(styles.darkItem, darkStyling && styles.lightItem)} key={`${index}-link`}>
						{downloadComponent(downloadLink?.href?.text || '', downloadLink?.href?.url || '/')}
						{downloadLink?.description && (
							<span id="description" className={styles.label}>
								{downloadLink?.description}
							</span>
						)}
					</div>
				))}
			</div>
		</div>
	);
};

const downloadComponent = (title: string, url: string): React.JSX.Element => {
	return (
		<Button
			title={title}
			variant="tertiary-dark"
			icon={<Icon customIcon={<IcnChevronRight />} size="12px" />}
			iconPosition="left"
			href={url}
		/>
	);
};

export const renderDownloadLink = (file: { ext: string; size: number }, name?: string): string => {
	return `${name ? name?.replace(/\.[^./]+$/, '') : name ?? ''} ${
		file?.ext
			? `(${file.ext.slice(1, file.ext.length).toUpperCase()}${
					file.size ? `, ${(file.size / 1000).toFixed(2)} MB` : ''
			  })`
			: ``
	} `;
};

export default Downloads;
