import { DOMNode, domToReact } from 'html-react-parser';
import { Element } from 'domhandler/lib/node';
import { parseNode } from '../sanitize-and-parse';
import { parseMobileTable } from './parse-table-mobile';

import styles from 'modules/table-module/table-module.module.scss';

const parseNestdChildren = (children: DOMNode[] | undefined): (React.JSX.Element | null)[] => {
	if (children) return children.map((child) => parseNode(child));
	return [];
};

const parseChildren = (children: DOMNode[] | undefined): string | React.JSX.Element | React.JSX.Element[] => {
	if (children) return domToReact(children);
	return '';
};

export const table = ({ children }: Element): React.JSX.Element => {
	return (
		<div className={styles.schedulingTable}>
			<table>{parseNestdChildren(children)}</table>
			{parseMobileTable(children)}
		</div>
	);
};

export const thead = ({ children, attribs }: Element): React.JSX.Element => (
	<thead {...attribs}>{parseNestdChildren(children)}</thead>
);

export const tbody = ({ children, attribs }: Element): React.JSX.Element => (
	<tbody {...attribs}>{parseNestdChildren(children)}</tbody>
);

export const tfoot = ({ children, attribs }: Element): React.JSX.Element => (
	<tfoot {...attribs}>{parseNestdChildren(children)}</tfoot>
);

export const tr = ({ children, attribs }: Element): React.JSX.Element => {
	return <tr {...attribs}>{parseNestdChildren(children)}</tr>;
};

export const th = ({ children, attribs }: Element): React.JSX.Element => {
	return <th {...attribs}>{parseChildren(children)}</th>;
};

export const td = ({ children, attribs }: Element): React.JSX.Element => {
	return <td {...attribs}>{parseChildren(children)}</td>;
};

export const caption = ({ children, attribs }: Element): React.JSX.Element => (
	<caption {...attribs}>{parseChildren(children)}</caption>
);
