import { DOMNode, domToReact, Text } from 'html-react-parser';
import { Element } from 'domhandler/lib/node';
import { flatMapDeep } from 'lodash';
import MobileTable from 'modules/table-module/mobile-table';
import { parseNode } from '../sanitize-and-parse';

export const parseMobileTable = (children: DOMNode[]): React.JSX.Element => {
	const thead = children.find((child) => (child as Element).name === 'thead');
	const tbody = children.find((child) => (child as Element).name !== 'thead');

	const headerRows = cellMapping(thead);
	const bodyRows = cellMapping(tbody);

	return <MobileTable header={headerRows[0]} body={bodyRows} />;
};

const cellMapping = (data?: DOMNode | Element): any => {
	const tableElements = ['thead', 'tbody', 'tr'];

	const isElement = data && 'name' in data;
	const isTableElement = isElement && tableElements.some((element) => element === data.name);
	const hasChildren = data && 'children' in data && data.children.length > 0;

	if (isTableElement && hasChildren) {
		return data.children.map((child) => cellMapping(child) as React.JSX.Element[]);
	} else if (hasChildren) {
		return [domToReact(data.children)];
	}

	return [];
};

const getNestedText = (element: Element | Text): (React.JSX.Element | null)[] => {
	const isTableElement = 'name' in element && (element.name === 'td' || element.name === 'th' || element.name === 'tr');

	if (isTableElement && 'children' in element) {
		return flatMapDeep(element.children.map((child) => getNestedText(child as Element)));
	}

	if ('data' in element) {
		return [<>{element.data}</>];
	}

	return [parseNode(element)];
};
